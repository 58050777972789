import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStoreService {
    restaurant = new Subject<any>();
    cart = new Subject<any>();
    note;
    resuturantChangeRemovedCart = false;
    user = new Subject<any>();
    userCart = new Subject<any>();
    loggedIn = new Subject<any>();
    constructor() { }

    hardlogout() {
        let AutologinUrl = this.get('autoLoginUrl');
        localStorage.clear();
        this.set('autoLoginUrl', AutologinUrl);
    }

    set(key: string, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    get(key: string) {
        return JSON.parse(localStorage.getItem(key));
    }

    getItem(keyname){
        return localStorage.getItem(keyname);
    }
    
    setItem(keyname, value){
        localStorage.setItem(keyname, value);
    }

    getRestaurantuuid(key: string) {
        return localStorage.getItem(key);
    }

    clear(){
        let AutologinUrl = this.get('autoLoginUrl');
        localStorage.clear();
        this.set('autoLoginUrl', AutologinUrl);
    }

    remove(key?: string) {
        if (key) { localStorage.removeItem(key); }
        // else { localStorage.clear(); }
    }

    getUser(){
        return this.get('user');
    }

    isLoggedIn(): boolean{
        // console.log(this.getUser())
        return this.getUser() ? true : false;
    }

    getUserId(): string{
        if( this.getUser() === null ){
          return "guest";
        }
        else{
          return this.getUser().user_information_uuid;
        }   
      }

    setUserCart(cart){
        localStorage.removeItem('discountAmount');
        const data: any= {}; //this.getUserData();
        //data.this.getUser().user_information_uuid.cart = cart;
        //console.log(cart)
        data.cart = cart;
        this.userCart.next(cart);
        this.set(this.getUserId(),data);
    }
    
    getUserCart(){
        if (this.getUser() == null) {
            return [];
        } else {
            let data = this.get(this.getUser().user_information_uuid);
            if (data == null) {
                return [];
            }
            return data.cart;
        }
    }

       /** Get selected language */
       getLanguageCode() {
        let langCode = "en";
        let langObj = this.get('language');
        if(langObj) {
            if(langObj.language_code != null) {
                langCode = langObj?.language_code;
            }
        }
        return langCode;
    }

    getUserSelectedCart() {
        if (this.getUser() == null) {
            return [];
        } else {
            let data = this.get("selectedItemsInCart");
            if (data == null) {
                return [];
            }
            return data;
        }
    }

    getCart() {
        return this.get('cart');
    }

    setCart(value) {
        localStorage.removeItem('discountAmount');
        this.cart.next(value);
        this.set('cart', value);
    }

    getRestaurant() {
        return this.get('restaurant');
    }

    getLanguageUuid() {
        let languuid = "d12cc4046dbc53630c2ba3afcb18640c";
        let langObj = this.get('language');
        if(langObj) {
            if(langObj.language_uuid) {
                languuid = langObj.language_uuid;
            }
        }
        return languuid;
    }

    getCountry() {
        return this.get('selectedCountry'); 
    }

}